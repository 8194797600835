// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUserCheck, IconFileCheck, IconFilePencil, IconFileUpload } from '@tabler/icons';

// projects import
import NewRegistrationCount from 'ui-component/staffMenuChipCounts/registrar/newRegistrationCount';
import RenewalCount from 'ui-component/staffMenuChipCounts/registrar/renewalCount';
import AmendmentCertificateCount from 'ui-component/staffMenuChipCounts/registrar/amendmentCertificateCount';

// constant
const icons = {
    IconUserCheck,
    IconFileCheck,
    IconFilePencil,
    IconFileUpload
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Registrar = {
    id: 'registrar',
    type: 'group',
    children: [
        {
            id: 'new-registration-data',
            title: <FormattedMessage id="new-registration-data" />,
            type: 'item',
            url: '/re/new-registration',
            icon: icons.IconUserCheck,
            breadcrumbs: false,
            chip: {
                label: <NewRegistrationCount />,
                color: 'primary'
            }
        },
        {
            id: 'renewal-data',
            title: <FormattedMessage id="renewal-data" />,
            type: 'item',
            url: '/re/renewal-application',
            icon: icons.IconFileCheck,
            breadcrumbs: false,
            chip: {
                label: <RenewalCount />,
                color: 'primary'
            }
        },
        {
            id: 'amendment-of-certificate-data',
            title: <FormattedMessage id="amendment-of-certificate-data" />,
            type: 'item',
            url: '/re/certificate-ammendment',
            icon: icons.IconFilePencil,
            breadcrumbs: false,
            chip: {
                label: <AmendmentCertificateCount />,
                color: 'primary'
            }
        },
        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'collapse',
            icon: icons.IconFileUpload,
            children: [
                {
                    id: 'new-industry-registration',
                    title: <FormattedMessage id="new-industry-registration" />,
                    type: 'item',
                    url: '/re/reports/new-industry-registration-details'
                    // icon: icons.IconFileUpload,
                    // breadcrumbs: false
                    // chip: {
                    //     label: <AnnualReturnCount />,
                    //     color: 'primary'
                    // }
                },
                {
                    id: 'renewal-of-certificate',
                    title: <FormattedMessage id="renewal-of-certificate" />,
                    type: 'item',
                    url: '/re/reports/renewal-certificate-of-registration'
                    // icon: icons.IconFileUpload,
                    // breadcrumbs: false
                    // chip: {
                    //     label: <AnnualReturnCount />,
                    //     color: 'primary'
                    // }
                },
                {
                    id: 'amendment-of-certificate-details',
                    title: <FormattedMessage id="amendment-of-certificate-details" />,
                    type: 'item',
                    url: '/re/reports/amendment-certificate-details'
                    // icon: icons.IconFileUpload,
                    // breadcrumbs: false
                    // chip: {
                    //     label: <AnnualReturnCount />,
                    //     color: 'primary'
                    // }
                },
                {
                    id: 'submission-of-annual-returns',
                    title: <FormattedMessage id="submission-of-annual-returns" />,
                    type: 'item',
                    url: '/re/reports/submission-annual-reports'
                    // icon: icons.IconFileUpload,
                    // breadcrumbs: false
                    // chip: {
                    //     label: <AnnualReturnCount />,
                    //     color: 'primary'
                    // }
                },
                {
                    id: 'product-details',
                    title: <FormattedMessage id="product-details" />,
                    type: 'item',
                    url: '/re/reports/product-details'
                    // icon: icons.IconFileUpload,
                    // breadcrumbs: false
                    // chip: {
                    //     label: <AnnualReturnCount />,
                    //     color: 'primary'
                    // }
                }
            ]
        }
        // {
        //     id: 'amendment-of-other-data',
        //     title: <FormattedMessage id="amendment-of-other-data" />,
        //     type: 'item',
        //     url: '/re/other-ammendment',
        //     icon: icons.IconFilePencil,
        //     breadcrumbs: false,
        //     chip: {
        //         label: '2',
        //         color: 'primary'
        //     }
        // }
    ]
};

export default Registrar;
