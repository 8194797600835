import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const ManageUsers = Loadable(lazy(() => import('views/staff/admin')));
const AuthRegisterStaff = Loadable(lazy(() => import('views/pages/authentication/StaffRegister')));

const ProductDetailsReport = Loadable(lazy(() => import('views/staff/report/ProductDetails')));
const SubmissionofAnnualReturns = Loadable(lazy(() => import('views/staff/report/SubmissionOfAnnualReturns')));
const AmendmentCertificateDetails = Loadable(lazy(() => import('views/staff/report/AmendmentCertificateDetails')));
const RenewalCertificateRegistration = Loadable(lazy(() => import('views/staff/report/RenewalCertificateRegistration')));
const NewIndustryRegistrationDetails = Loadable(lazy(() => import('views/staff/report/NewIndustryRegistrationDetails')));

// ==============================|| MAIN ROUTING ||============================== //

const ADMINMainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/admin/create-staff-member',
            element: <AuthRegisterStaff />
        },
        {
            path: '/admin/staff-members',
            element: <ManageUsers />
        },
        {
            path: '/reports/product-details',
            element: <ProductDetailsReport />
        },
        {
            path: '/reports/submission-annual-reports',
            element: <SubmissionofAnnualReturns />
        },
        {
            path: '/reports/amendment-certificate-details',
            element: <AmendmentCertificateDetails />
        },
        {
            path: '/reports/renewal-certificate-of-registration',
            element: <RenewalCertificateRegistration />
        },
        {
            path: '/reports/new-industry-registration-details',
            element: <NewIndustryRegistrationDetails />
        }
    ]
};

export default ADMINMainRoutes;
