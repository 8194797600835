import { callAPI } from '../../axios';

// New Registration

async function getNewIndustryDashboard() {
    const options = {
        method: 'GET',
        url: '/re/new-industry-dashboard',
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
        }
    };
    const res = await callAPI(options, false);
    return res;
}

// Renewal

async function getRenewalDashboard() {
    const options = {
        method: 'GET',
        url: '/renewal/re/renewal-dashboard',
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
        }
    };
    const res = await callAPI(options, false);
    return res;
}

// Amendment of Certificate

async function getACDashboard() {
    const options = {
        method: 'GET',
        url: '/ac/re/ac-dashboard',
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
        }
    };
    const res = await callAPI(options, false);
    return res;
}

const DashboardREServices = {
    getNewIndustryDashboard,
    getRenewalDashboard,
    getACDashboard
};
export default DashboardREServices;
