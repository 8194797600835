// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUserCheck, IconFileCheck, IconFilePencil, IconFileUpload } from '@tabler/icons';

// constant
const icons = {
    IconUserCheck,
    IconFileCheck,
    IconFilePencil,
    IconFileUpload
};

// ==============================|| APPLICANT MENU ITEMS ||============================== //

const Applicant = {
    id: 'applicant',
    type: 'group',
    children: [
        {
            id: 'registration',
            title: <FormattedMessage id="registration" />,
            type: 'item',
            url: '/industry-registration',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        },
        {
            id: 'renewal',
            title: <FormattedMessage id="renewal" />,
            type: 'item',
            url: '/renewal-application',
            icon: icons.IconFileCheck,
            breadcrumbs: false
        },
        {
            id: 'amendment-of-certificate',
            title: <FormattedMessage id="amendment-of-certificate" />,
            type: 'item',
            url: '/certificate-ammendment',
            icon: icons.IconFilePencil,
            breadcrumbs: false
        },
        // {
        //     id: 'amendment-of-other-details',
        //     title: <FormattedMessage id="amendment-of-other-details" />,
        //     type: 'item',
        //     url: '/other-ammendment',
        //     icon: icons.IconFilePencil,
        //     breadcrumbs: false
        // },
        {
            id: 'annual-returns',
            title: <FormattedMessage id="annual-returns" />,
            type: 'item',
            url: '/annual-return',
            icon: icons.IconFileUpload,
            breadcrumbs: false
        }
    ]
};

export default Applicant;
