import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const GeneralFieldInspection = Loadable(lazy(() => import('views/staff/fieldInspector/newRegistration/generate-field-inspection')));
const NewRegistrationFieldInspector = Loadable(lazy(() => import('views/staff/fieldInspector/newRegistration')));
const RenewalFieldInspector = Loadable(lazy(() => import('views/staff/fieldInspector/renewal')));
const CertificateAmendmentFieldInspector = Loadable(lazy(() => import('views/staff/fieldInspector/certificateAmendment')));
const OtherAmendmentFieldInspector = Loadable(lazy(() => import('views/staff/fieldInspector/otherAmendment')));
const ChangeVisitDate = Loadable(lazy(() => import('views/staff/fieldInspector/ChangeVisitDate')));

const RenewelAssignApplication = Loadable(lazy(() => import('views/staff/fieldInspector/renewal/field-inspection')));
const RenewelGeneralFieldInspection = Loadable(lazy(() => import('views/staff/fieldInspector/renewal/generate-field-inspection')));
const AmendmentAssignApplication = Loadable(lazy(() => import('views/staff/fieldInspector/certificateAmendment/field-inspection')));
const AmendmentGeneralFieldInspection = Loadable(
    lazy(() => import('views/staff/fieldInspector/certificateAmendment/generate-field-inspection'))
);
const OtherAmendmentAssignApplication = Loadable(lazy(() => import('views/staff/fieldInspector/otherAmendment/assign-application')));
const ApplicationsForInspection = Loadable(lazy(() => import('views/staff/fieldInspector/newRegistration/applications-for-inspection')));
const FieldInspectionByFieldInspector = Loadable(lazy(() => import('views/staff/fieldInspector/newRegistration/field-inspection')));
const AcceptAssignedApplications = Loadable(lazy(() => import('views/staff/fieldInspector/renewal/applications-for-inspection')));
const AcceptAssignedApplicationsAC = Loadable(
    lazy(() => import('views/staff/fieldInspector/certificateAmendment/applications-for-inspection'))
);

const ProductDetailsReport = Loadable(lazy(() => import('views/staff/report/ProductDetails')));
const SubmissionofAnnualReturns = Loadable(lazy(() => import('views/staff/report/SubmissionOfAnnualReturns')));
const AmendmentCertificateDetails = Loadable(lazy(() => import('views/staff/report/AmendmentCertificateDetails')));
const RenewalCertificateRegistration = Loadable(lazy(() => import('views/staff/report/RenewalCertificateRegistration')));
const NewIndustryRegistrationDetails = Loadable(lazy(() => import('views/staff/report/NewIndustryRegistrationDetails')));

// ==============================|| MAIN ROUTING ||============================== //

const FIMainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/fi/new-registration',
            element: <NewRegistrationFieldInspector />
        },

        {
            path: '/fi/general-field-inspection',
            element: <GeneralFieldInspection />
        },
        {
            path: '/fi/renewal-application',
            element: <RenewalFieldInspector />
        },
        {
            path: '/fi/other-ammendment',
            element: <OtherAmendmentFieldInspector />
        },
        {
            path: '/fi/change-visit-date',
            element: <ChangeVisitDate />
        },

        {
            path: '/fi/certificate-ammendment',
            element: <CertificateAmendmentFieldInspector />
        },
        {
            path: '/fi/renewel-general-field-inspection',
            element: <RenewelGeneralFieldInspection />
        },
        {
            path: '/fi/renewel-assign-application',
            element: <RenewelAssignApplication />
        },
        {
            path: '/fi/amendment-assign-application',
            element: <AmendmentAssignApplication />
        },
        {
            path: '/fi/amendment-general-field-inspection',
            element: <AmendmentGeneralFieldInspection />
        },
        {
            path: '/fi/otheramendment-assign-application',
            element: <OtherAmendmentAssignApplication />
        },
        {
            path: '/fi/applications-for-inspection',
            element: <ApplicationsForInspection />
        },
        {
            path: '/fi/field-inspection-report',
            element: <FieldInspectionByFieldInspector />
        },
        {
            path: '/fi/accept-assigned-applications-for-inspection',
            element: <AcceptAssignedApplications />
        },
        {
            path: '/fi/amendment-certificate-accept-assigned-applications-for-inspection',
            element: <AcceptAssignedApplicationsAC />
        },
        {
            path: '/fi/reports/product-details',
            element: <ProductDetailsReport />
        },
        {
            path: '/fi/reports/submission-annual-reports',
            element: <SubmissionofAnnualReturns />
        },
        {
            path: '/fi/reports/amendment-certificate-details',
            element: <AmendmentCertificateDetails />
        },
        {
            path: '/fi/reports/renewal-certificate-of-registration',
            element: <RenewalCertificateRegistration />
        },
        {
            path: '/fi/reports/new-industry-registration-details',
            element: <NewIndustryRegistrationDetails />
        }
    ]
};

export default FIMainRoutes;
