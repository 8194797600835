import { useEffect, useState } from 'react';
import DashboardSOServices from 'services/staffServices/subjectOfficerServices/soDashboardService';

const RenewalCount = () => {
    const [chipNumber, setChipNumber] = useState(0);

    const fetchData = async () => {
        try {
            const res = await DashboardSOServices.getRenewalDashboard();
            setChipNumber(res?.row_count?.total_cases);
        } catch (e) {
            // console.log(e);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return chipNumber;
};

export default RenewalCount;
