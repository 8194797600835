/* eslint-disable react-hooks/rules-of-hooks */
import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import ApplicantMainRoutes from './applicantMainRoutes';
import ADMainRoutes from './adMainRoutes';
import FIMainRoutes from './fiMainRoutes';
import RDMainRoutes from './rdMainRoutes';
import REMainRoutes from './reMainRoutes';
import SOMainRoutes from './soMainRoutes';
import Loadable from 'ui-component/Loadable';
import useAuth from 'hooks/useAuth';
import ADMINMainRoutes from './adminMainRoutes';

const LandingPage = Loadable(lazy(() => import('views/pages/landing')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user } = useAuth();
    // console.log(user?.user_role);

    const commonRoutes = [{ path: '/', element: <LandingPage /> }, LoginRoutes, MainRoutes];

    switch (user?.user_role) {
        case 'Applicant':
            return useRoutes([...commonRoutes, ApplicantMainRoutes]);
        case 'Subject Officer':
            return useRoutes([...commonRoutes, SOMainRoutes]);
        case 'Assistant Director':
            return useRoutes([...commonRoutes, ADMainRoutes]);
        case 'Field Inspector':
            return useRoutes([...commonRoutes, FIMainRoutes]);
        case 'Regional Director':
            return useRoutes([...commonRoutes, RDMainRoutes]);
        case 'Registrar':
            return useRoutes([...commonRoutes, REMainRoutes]);
        case 'Admin':
            return useRoutes([...commonRoutes, ADMINMainRoutes]);
        default:
            return useRoutes(commonRoutes);
    }
}
