import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const PrintCertificate = Loadable(lazy(() => import('views/staff/subjectOfficer/newRegistration/print-certificate')));
const UploadCertificate = Loadable(lazy(() => import('views/staff/subjectOfficer/newRegistration/upload-certificate')));
const IssueCertificate = Loadable(lazy(() => import('views/staff/subjectOfficer/newRegistration/issue-certificate')));
const NewRegistrationSubjectOfficer = Loadable(lazy(() => import('views/staff/subjectOfficer/newRegistration')));
const RenewalSubjectOfficer = Loadable(lazy(() => import('views/staff/subjectOfficer/renewal')));
const CertificateAmendmentSubjectOfficer = Loadable(lazy(() => import('views/staff/subjectOfficer/certificateAmendment')));
const OtherAmendmentSubjectOfficer = Loadable(lazy(() => import('views/staff/subjectOfficer/otherAmendment')));
const RenewalPrintCertificate = Loadable(lazy(() => import('views/staff/subjectOfficer/renewal/print-certificate')));
const RenewelUploadCertificate = Loadable(lazy(() => import('views/staff/subjectOfficer/renewal/upload-certificate')));
const RenewelIssueCertificate = Loadable(lazy(() => import('views/staff/subjectOfficer/renewal/issue-certificate')));
const AmendmentPrintCertificate = Loadable(lazy(() => import('views/staff/subjectOfficer/certificateAmendment/print-certificate')));
const AmendmentUploadCertificate = Loadable(lazy(() => import('views/staff/subjectOfficer/certificateAmendment/upload-certificate')));
const AmendmentIssueCertificate = Loadable(lazy(() => import('views/staff/subjectOfficer/certificateAmendment/issue-certificate')));
const AssignedApplications = Loadable(lazy(() => import('views/staff/subjectOfficer/newRegistration/assigned-applications')));
const AssignedApplicationsRenewal = Loadable(lazy(() => import('views/staff/subjectOfficer/renewal/assigned-applications')));
const AssignedApplicationsAC = Loadable(lazy(() => import('views/staff/subjectOfficer/certificateAmendment/assigned-applications')));
const AssignedApplicationsAO = Loadable(lazy(() => import('views/staff/subjectOfficer/otherAmendment/assigned-applications')));

const ProductDetailsReport = Loadable(lazy(() => import('views/staff/report/ProductDetails')));
const SubmissionofAnnualReturns = Loadable(lazy(() => import('views/staff/report/SubmissionOfAnnualReturns')));
const AmendmentCertificateDetails = Loadable(lazy(() => import('views/staff/report/AmendmentCertificateDetails')));
const RenewalCertificateRegistration = Loadable(lazy(() => import('views/staff/report/RenewalCertificateRegistration')));
const NewIndustryRegistrationDetails = Loadable(lazy(() => import('views/staff/report/NewIndustryRegistrationDetails')));

// ==============================|| MAIN ROUTING ||============================== //

const SOMainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/so/new-registration',
            element: <NewRegistrationSubjectOfficer />
        },
        {
            path: '/so/print-certificate',
            element: <PrintCertificate />
        },
        {
            path: '/so/upload-certificate',
            element: <UploadCertificate />
        },
        {
            path: '/so/issue-certificate',
            element: <IssueCertificate />
        },
        {
            path: '/so/renewal-application',
            element: <RenewalSubjectOfficer />
        },
        {
            path: '/so/certificate-ammendment',
            element: <CertificateAmendmentSubjectOfficer />
        },
        {
            path: '/so/other-ammendment',
            element: <OtherAmendmentSubjectOfficer />
        },
        {
            path: '/so/renewel-issue-certificate',
            element: <RenewelIssueCertificate />
        },
        {
            path: '/so/renewel-print-certificate',
            element: <RenewalPrintCertificate />
        },
        {
            path: '/so/renewel-upload-certificate',
            element: <RenewelUploadCertificate />
        },
        {
            path: '/so/amendment-print-certificate',
            element: <AmendmentPrintCertificate />
        },
        {
            path: '/so/amendment-upload-certificate',
            element: <AmendmentUploadCertificate />
        },
        {
            path: '/so/amendment-issue-certificate',
            element: <AmendmentIssueCertificate />
        },
        {
            path: '/so/assigned-applications',
            element: <AssignedApplications />
        },
        {
            path: '/so/renewal-assigned-applications',
            element: <AssignedApplicationsRenewal />
        },
        {
            path: '/so/amendment-certificate-assigned-applications',
            element: <AssignedApplicationsAC />
        },
        {
            path: '/so/other-amendment-assigned-applications',
            element: <AssignedApplicationsAO />
        },
        {
            path: '/so/reports/product-details',
            element: <ProductDetailsReport />
        },
        {
            path: '/so/reports/submission-annual-reports',
            element: <SubmissionofAnnualReturns />
        },
        {
            path: '/so/reports/amendment-certificate-details',
            element: <AmendmentCertificateDetails />
        },
        {
            path: '/so/reports/renewal-certificate-of-registration',
            element: <RenewalCertificateRegistration />
        },
        {
            path: '/so/reports/new-industry-registration-details',
            element: <NewIndustryRegistrationDetails />
        }
    ]
};

export default SOMainRoutes;
