import Applicant from './applicant';
import Dashboard from './dashboard';

// ==============================|| APPLICANT ITEMS ||============================== //

const ApplicantItems = {
    items: [Dashboard, Applicant]
};

export default ApplicantItems;
