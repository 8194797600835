// export const FIREBASE_API = {
//     apiKey: 'AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM',
//     authDomain: 'berry-material-react.firebaseapp.com',
//     projectId: 'berry-material-react',
//     storageBucket: 'berry-material-react.appspot.com',
//     messagingSenderId: '901111229354',
//     appId: '1:901111229354:web:a5ae5aa95486297d69d9d3',
//     measurementId: 'G-MGJHSL8XW3'
// };

export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

// export const AUTH0_API = '';

export const DASHBOARD_PATH = '/dashboard';
export const API_END = 'https://reg.industry.gov.lk/api/v1/';
// export const API_END = 'http://127.0.0.1:5000/v1/';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
