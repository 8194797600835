// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUserCheck, IconFileCheck, IconFilePencil, IconFileUpload } from '@tabler/icons';

// projects import
import NewRegistrationCount from 'ui-component/staffMenuChipCounts/assistantDirector/newRegistrationCount';
import RenewalCount from 'ui-component/staffMenuChipCounts/assistantDirector/renewalCount';
import AmendmentCertificateCount from 'ui-component/staffMenuChipCounts/assistantDirector/amendmentCertificateCount';
import AnnualReturnCount from 'ui-component/staffMenuChipCounts/assistantDirector/annualReturnCount';

// constant
const icons = {
    IconUserCheck,
    IconFileCheck,
    IconFilePencil,
    IconFileUpload
};

// ==============================|| ASSISTANT DIRECTOR MENU ITEMS ||============================== //

const AssistantDirector = {
    id: 'assistantDirector',
    type: 'group',
    children: [
        {
            id: 'new-registration-data',
            title: <FormattedMessage id="new-registration-data" />,
            type: 'item',
            url: '/ad/new-registration',
            icon: icons.IconUserCheck,
            breadcrumbs: false,
            chip: {
                label: <NewRegistrationCount />,
                color: 'primary'
            }
        },
        {
            id: 'renewal-data',
            title: <FormattedMessage id="renewal-data" />,
            type: 'item',
            url: '/ad/renewal-application',
            icon: icons.IconFileCheck,
            breadcrumbs: false,
            chip: {
                label: <RenewalCount />,
                color: 'primary'
            }
        },
        {
            id: 'amendment-of-certificate-data',
            title: <FormattedMessage id="amendment-of-certificate-data" />,
            type: 'item',
            url: '/ad/certificate-ammendment',
            icon: icons.IconFilePencil,
            breadcrumbs: false,
            chip: {
                label: <AmendmentCertificateCount />,
                color: 'primary'
            }
        },
        // {
        //     id: 'amendment-of-other-data',
        //     title: <FormattedMessage id="amendment-of-other-data" />,
        //     type: 'item',
        //     url: '/ad/other-ammendment',
        //     icon: icons.IconFilePencil,
        //     breadcrumbs: false,
        //     chip: {
        //         label: '2',
        //         color: 'primary'
        //     }
        // },
        {
            id: 'annual-returns-data',
            title: <FormattedMessage id="annual-returns-data" />,
            type: 'item',
            url: '/ad/annual-return',
            icon: icons.IconFileUpload,
            breadcrumbs: false,
            chip: {
                label: <AnnualReturnCount />,
                color: 'primary'
            }
        },
        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'collapse',
            icon: icons.IconFileUpload,
            children: [
                {
                    id: 'new-industry-registration',
                    title: <FormattedMessage id="new-industry-registration" />,
                    type: 'item',
                    url: '/ad/reports/new-industry-registration-details'
                    // icon: icons.IconFileUpload,
                    // breadcrumbs: false
                    // chip: {
                    //     label: <AnnualReturnCount />,
                    //     color: 'primary'
                    // }
                },
                {
                    id: 'renewal-of-certificate',
                    title: <FormattedMessage id="renewal-of-certificate" />,
                    type: 'item',
                    url: '/ad/reports/renewal-certificate-of-registration'
                    // icon: icons.IconFileUpload,
                    // breadcrumbs: false
                    // chip: {
                    //     label: <AnnualReturnCount />,
                    //     color: 'primary'
                    // }
                },
                {
                    id: 'amendment-of-certificate-details',
                    title: <FormattedMessage id="amendment-of-certificate-details" />,
                    type: 'item',
                    url: '/ad/reports/amendment-certificate-details'
                    // icon: icons.IconFileUpload,
                    // breadcrumbs: false
                    // chip: {
                    //     label: <AnnualReturnCount />,
                    //     color: 'primary'
                    // }
                },
                {
                    id: 'submission-of-annual-returns',
                    title: <FormattedMessage id="submission-of-annual-returns" />,
                    type: 'item',
                    url: '/ad/reports/submission-annual-reports'
                    // icon: icons.IconFileUpload,
                    // breadcrumbs: false
                    // chip: {
                    //     label: <AnnualReturnCount />,
                    //     color: 'primary'
                    // }
                },
                {
                    id: 'product-details',
                    title: <FormattedMessage id="product-details" />,
                    type: 'item',
                    url: '/ad/reports/product-details'
                    // icon: icons.IconFileUpload,
                    // breadcrumbs: false
                    // chip: {
                    //     label: <AnnualReturnCount />,
                    //     color: 'primary'
                    // }
                }
            ]
        }
        // {
        //     id: 'revoke-applications-data',
        //     title: <FormattedMessage id="revoke-applications-data" />,
        //     type: 'item',
        //     url: '/ad/revoke-applications',
        //     icon: icons.IconFileUpload,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'revoke-applications-subject-officer',
        //     title: <FormattedMessage id="revoke-applications-subject-officer" />,
        //     type: 'item',
        //     url: '/ad/revoke-applications-subject-officer',
        //     icon: icons.IconFileUpload,
        //     breadcrumbs: false
        // }
    ]
};

export default AssistantDirector;
