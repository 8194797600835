import { useEffect, useState } from 'react';
import DashboardFIServices from 'services/staffServices/fieldInspectorServices/fiDashboardService';

const RenewalCount = () => {
    const [chipNumber, setChipNumber] = useState(0);

    const fetchData = async () => {
        try {
            const res = await DashboardFIServices.getRenewalDashboard();
            setChipNumber(res?.row_count?.total_cases);
        } catch (e) {
            // console.log(e);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return chipNumber;
};

export default RenewalCount;
