import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const AssignApplicationRD = Loadable(lazy(() => import('views/staff/regionalDirector/newRegistration/assign-field-inspector')));
const NewRegistrationRegionalDirector = Loadable(lazy(() => import('views/staff/regionalDirector/newRegistration')));
const RenewalRegionalDirector = Loadable(lazy(() => import('views/staff/regionalDirector/renewal')));
const CertificateAmendmentRegionalDirector = Loadable(lazy(() => import('views/staff/regionalDirector/certificateAmendment')));
const CertifyInspectionReport = Loadable(lazy(() => import('views/staff/regionalDirector/newRegistration/certify-inspection-report')));
const CertifyInspectionReportRenewal = Loadable(lazy(() => import('views/staff/regionalDirector/renewal/certify-inspection-report')));
const CertifyInspectionReportAC = Loadable(
    lazy(() => import('views/staff/regionalDirector/certificateAmendment/certify-inspection-report'))
);
const ApplicationAssignFIAC = Loadable(lazy(() => import('views/staff/regionalDirector/certificateAmendment/assign-field-inspector')));
// const AssignApplicationRegionalDirector = Loadable(
//     lazy(() => import('views/staff/regionalDirector/newRegistration/assign-field-inspector'))
// );
const ApplicationPriorityChangeRegionalDirector = Loadable(
    lazy(() => import('views/staff/assistantDirector/newRegistration/application-priority-change'))
);
const AssignApplicationRDRenewal = Loadable(lazy(() => import('views/staff/regionalDirector/renewal/assign-field-inspector')));
const ProductDetailsReport = Loadable(lazy(() => import('views/staff/report/ProductDetails')));
const SubmissionofAnnualReturns = Loadable(lazy(() => import('views/staff/report/SubmissionOfAnnualReturns')));
const AmendmentCertificateDetails = Loadable(lazy(() => import('views/staff/report/AmendmentCertificateDetails')));
const RenewalCertificateRegistration = Loadable(lazy(() => import('views/staff/report/RenewalCertificateRegistration')));
const NewIndustryRegistrationDetails = Loadable(lazy(() => import('views/staff/report/NewIndustryRegistrationDetails')));

// ==============================|| MAIN ROUTING ||============================== //

const RDMainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/rd/new-registration',
            element: <NewRegistrationRegionalDirector />
        },
        {
            path: '/rd/renewal-application',
            element: <RenewalRegionalDirector />
        },
        {
            path: '/rd/certificate-ammendment',
            element: <CertificateAmendmentRegionalDirector />
        },
        {
            path: '/rd/certify-inspection-report',
            element: <CertifyInspectionReport />
        },
        {
            path: '/rd/certify-inspection-report-renewal',
            element: <CertifyInspectionReportRenewal />
        },
        {
            path: '/rd/amendment-certificate-certify-inspection-report',
            element: <CertifyInspectionReportAC />
        },
        {
            path: '/rd/amendment-certificate-application-assign-to-fi',
            element: <ApplicationAssignFIAC />
        },
        // {
        //     path: '/rd/assign-application',
        //     element: <AssignApplicationRegionalDirector />
        // },
        {
            path: '/rd/application-priority-change',
            element: <ApplicationPriorityChangeRegionalDirector />
        },
        {
            path: '/rd/assign-application-to-fi',
            element: <AssignApplicationRD />
        },
        {
            path: '/rd/assign-application-to-fi-renewal',
            element: <AssignApplicationRDRenewal />
        },
        {
            path: '/rd/reports/product-details',
            element: <ProductDetailsReport />
        },
        {
            path: '/rd/reports/submission-annual-reports',
            element: <SubmissionofAnnualReturns />
        },
        {
            path: '/rd/reports/amendment-certificate-details',
            element: <AmendmentCertificateDetails />
        },
        {
            path: '/rd/reports/renewal-certificate-of-registration',
            element: <RenewalCertificateRegistration />
        },
        {
            path: '/rd/reports/new-industry-registration-details',
            element: <NewIndustryRegistrationDetails />
        }
    ]
};

export default RDMainRoutes;
