import { useEffect, useState } from 'react';
import DashboardREServices from 'services/staffServices/registrarServices/reDashboardService';

const RenewalCount = () => {
    const [chipNumber, setChipNumber] = useState(0);

    const fetchData = async () => {
        try {
            const res = await DashboardREServices.getRenewalDashboard();
            setChipNumber(res?.row_count?.total_cases);
        } catch (e) {
            // console.log(e);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return chipNumber;
};

export default RenewalCount;
