import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';
import ApplicantItems from 'menu-items/applicantItems';
import SubjectOfficerItems from 'menu-items/subjectOfficerItems';
import AssistantDirectorItems from 'menu-items/assistantDirectorItems';
import FieldInspectorItems from 'menu-items/fieldInspectorItems';
import RegionalDirectorItems from 'menu-items/regionalDirectorItems';
import RegistrarItems from 'menu-items/registrarItems';
// import staffItems from 'menu-items/staffItems';
// project imports
import NavGroup from './NavGroup';
import useAuth from 'hooks/useAuth';
import AdminItems from 'menu-items/adminItems';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { user } = useAuth();
    let navItems;
    // console.log(user);
    switch (user?.user_role) {
        case 'Applicant':
            navItems = ApplicantItems.items.map((item) => {
                switch (item.type) {
                    case 'group':
                        return <NavGroup key={item.id} item={item} />;
                    default:
                        return (
                            <Typography key={item.id} variant="h6" color="error" align="center">
                                Menu Items Error
                            </Typography>
                        );
                }
            });
            break;
        case 'Subject Officer':
            navItems = SubjectOfficerItems.items.map((item) => {
                switch (item.type) {
                    case 'group':
                        return <NavGroup key={item.id} item={item} />;
                    default:
                        return (
                            <Typography key={item.id} variant="h6" color="error" align="center">
                                Menu Items Error
                            </Typography>
                        );
                }
            });
            break;
        case 'Assistant Director':
            navItems = AssistantDirectorItems.items.map((item) => {
                switch (item.type) {
                    case 'group':
                        return <NavGroup key={item.id} item={item} />;
                    default:
                        return (
                            <Typography key={item.id} variant="h6" color="error" align="center">
                                Menu Items Error
                            </Typography>
                        );
                }
            });
            break;
        case 'Field Inspector':
            navItems = FieldInspectorItems.items.map((item) => {
                switch (item.type) {
                    case 'group':
                        return <NavGroup key={item.id} item={item} />;
                    default:
                        return (
                            <Typography key={item.id} variant="h6" color="error" align="center">
                                Menu Items Error
                            </Typography>
                        );
                }
            });
            break;
        case 'Regional Director':
            navItems = RegionalDirectorItems.items.map((item) => {
                switch (item.type) {
                    case 'group':
                        return <NavGroup key={item.id} item={item} />;
                    default:
                        return (
                            <Typography key={item.id} variant="h6" color="error" align="center">
                                Menu Items Error
                            </Typography>
                        );
                }
            });
            break;
        case 'Registrar':
            navItems = RegistrarItems.items.map((item) => {
                switch (item.type) {
                    case 'group':
                        return <NavGroup key={item.id} item={item} />;
                    default:
                        return (
                            <Typography key={item.id} variant="h6" color="error" align="center">
                                Menu Items Error
                            </Typography>
                        );
                }
            });
            break;
        case 'Admin':
            navItems = AdminItems.items.map((item) => {
                switch (item.type) {
                    case 'group':
                        return <NavGroup key={item.id} item={item} />;
                    default:
                        return (
                            <Typography key={item.id} variant="h6" color="error" align="center">
                                Menu Items Error
                            </Typography>
                        );
                }
            });
            break;
        default:
            navItems = (
                <Typography variant="h6" color="error" align="center">
                    Unknown User Role
                </Typography>
            );
            break;
    }

    return <>{navItems}</>;
};

export default memo(MenuList);
