import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const ScheduleInterview = Loadable(lazy(() => import('views/staff/registrar/newRegistration/schedule-interview')));
const InterviewResults = Loadable(lazy(() => import('views/staff/registrar/newRegistration/interview-results')));
const ApproveCertificate = Loadable(lazy(() => import('views/staff/registrar/newRegistration/approve-certificate')));
const NewRegistrationRegistrar = Loadable(lazy(() => import('views/staff/registrar/newRegistration')));
const RenewalRegistrar = Loadable(lazy(() => import('views/staff/registrar/renewal')));
const CertificateAmendmentRegistrar = Loadable(lazy(() => import('views/staff/registrar/certificateAmendment')));
const OtherAmendmentRegistrar = Loadable(lazy(() => import('views/staff/registrar/otherAmendment')));
const RenewelApproveCertificate = Loadable(lazy(() => import('views/staff/registrar/renewal/approve-certificate')));
const AmendmentApproveCertificate = Loadable(lazy(() => import('views/staff/registrar/certificateAmendment/approve-certificate')));
const OtherAmendmentApproveCertificate = Loadable(lazy(() => import('views/staff/registrar/otherAmendment/approve-certificate')));
const CancelApprovedCertificates = Loadable(lazy(() => import('views/staff/registrar/newRegistration/cancel-approved-certificates')));
const CancelApproveCertificates = Loadable(lazy(() => import('views/staff/registrar/renewal/cancel-approved-certificates')));
const CancelApproveCertificatesAC = Loadable(lazy(() => import('views/staff/registrar/certificateAmendment/cancel-approved-certificates')));

const ProductDetailsReport = Loadable(lazy(() => import('views/staff/report/ProductDetails')));
const SubmissionofAnnualReturns = Loadable(lazy(() => import('views/staff/report/SubmissionOfAnnualReturns')));
const AmendmentCertificateDetails = Loadable(lazy(() => import('views/staff/report/AmendmentCertificateDetails')));
const RenewalCertificateRegistration = Loadable(lazy(() => import('views/staff/report/RenewalCertificateRegistration')));
const NewIndustryRegistrationDetails = Loadable(lazy(() => import('views/staff/report/NewIndustryRegistrationDetails')));

// ==============================|| MAIN ROUTING ||============================== //

const REMainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/re/new-registration',
            element: <NewRegistrationRegistrar />
        },
        {
            path: '/re/review-application',
            element: <ScheduleInterview />
        },

        {
            path: '/re/interview-results',
            element: <InterviewResults />
        },
        {
            path: '/re/approve-certificate',
            element: <ApproveCertificate />
        },
        {
            path: '/re/renewal-application',
            element: <RenewalRegistrar />
        },
        {
            path: '/re/certificate-ammendment',
            element: <CertificateAmendmentRegistrar />
        },
        {
            path: '/re/other-ammendment',
            element: <OtherAmendmentRegistrar />
        },
        {
            path: '/re/renewel-approve-certificate',
            element: <RenewelApproveCertificate />
        },
        {
            path: '/re/amendment-approve-certificate',
            element: <AmendmentApproveCertificate />
        },
        {
            path: '/re/otheramendment-approve-certificate',
            element: <OtherAmendmentApproveCertificate />
        },
        {
            path: '/re/cancel-approved-certificates',
            element: <CancelApprovedCertificates />
        },
        {
            path: '/re/cancel-approve-certificates-renewal',
            element: <CancelApproveCertificates />
        },
        {
            path: '/re/amendment-certificate-cancel-approve-certificates',
            element: <CancelApproveCertificatesAC />
        },
        {
            path: '/re/reports/product-details',
            element: <ProductDetailsReport />
        },
        {
            path: '/re/reports/submission-annual-reports',
            element: <SubmissionofAnnualReturns />
        },
        {
            path: '/re/reports/amendment-certificate-details',
            element: <AmendmentCertificateDetails />
        },
        {
            path: '/re/reports/renewal-certificate-of-registration',
            element: <RenewalCertificateRegistration />
        },
        {
            path: '/re/reports/new-industry-registration-details',
            element: <NewIndustryRegistrationDetails />
        }
    ]
};

export default REMainRoutes;
