import Admin from './admin';
// import Dashboard from './dashboard';

// ==============================|| APPLICANT ITEMS ||============================== //

const AdminItems = {
    items: [Admin]
};

export default AdminItems;
