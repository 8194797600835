import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Typography } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';
import { useIntl } from 'react-intl';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const intl = useIntl();

    return (
        <Link component={RouterLink} to={DASHBOARD_PATH} sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
            <Logo />
            <Typography variant="h4" ml={2} sx={{ color: '#7c0505e8', textTransform: 'uppercase' }}>
                {intl.messages.ministry_of_industries}
            </Typography>
        </Link>
    );
};

export default LogoSection;
