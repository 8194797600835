import { callAPI } from '../axios';

// dropdown
async function getOrganizations() {
    const options = {
        method: 'GET',
        url: '/all-organizations',
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
        }
    };
    const res = await callAPI(options, false);
    return res;
}

async function updateCurrentOrgID(selectedOrgID) {
    const options = {
        method: 'POST',
        url: '/update-current-org',
        data: { selectedOrgID },
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
        }
    };
    const res = await callAPI(options, false);
    return res;
}

async function getOrganizationDetails(id) {
    const options = {
        method: 'GET',
        url: '/get-organization',
        params: { id }
    };
    const res = await callAPI(options, false);
    return res;
}

async function CreateHelpDesk(data) {
    const options = {
        method: 'POST',
        url: '/insert-help-request',
        data,
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
        }
    };
    const res = await callAPI(options, false);
    return res;
}

async function updatePassword(data) {
    const options = {
        method: 'PUT',
        url: '/update-password',
        data,
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
        }
    };
    const res = await callAPI(options, false);
    return res;
}

// Progress Timeline

async function getIndustryProgress(id) {
    const options = {
        method: 'GET',
        url: '/get-industry-progress2',
        params: { id },
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
        }
    };
    const res = await callAPI(options, false);
    return res;
}

async function getIndustryProgress2(id) {
    const options = {
        method: 'GET',
        url: '/get-industry-progress3',
        params: { id },
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
        }
    };
    const res = await callAPI(options, false);
    return res;
}

const DashboardServices = {
    getOrganizations,
    updateCurrentOrgID,
    getOrganizationDetails,
    CreateHelpDesk,
    updatePassword,
    getIndustryProgress,
    getIndustryProgress2
};
export default DashboardServices;
