import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
// import ProductDetails from 'views/staff/report/ProductDetails';

const StudyInspection = Loadable(lazy(() => import('views/staff/assistantDirector/newRegistration/study-inspection')));
const NewRegistrationAssistantDirector = Loadable(lazy(() => import('views/staff/assistantDirector/newRegistration')));
const AssignApplication = Loadable(lazy(() => import('views/staff/assistantDirector/newRegistration/assign-field-inspector')));
const RenewalAssistantDirector = Loadable(lazy(() => import('views/staff/assistantDirector/renewal')));
const CertificateAmendmentAssistantDirector = Loadable(lazy(() => import('views/staff/assistantDirector/certificateAmendment')));
const OtherAmendmentAssistantDirector = Loadable(lazy(() => import('views/staff/assistantDirector/otherAmendment')));
const AnnualReturnAssistantDirector = Loadable(lazy(() => import('views/staff/assistantDirector/annualReturn')));
const RenewelStudyInspection = Loadable(lazy(() => import('views/staff/assistantDirector/renewal/study-inspection')));
const AmendmentStudyInspection = Loadable(lazy(() => import('views/staff/assistantDirector/certificateAmendment/study-inspection')));
const RevokeApplications = Loadable(lazy(() => import('views/staff/assistantDirector/RevokeApplications')));
const ReceiveAssignNewApplications = Loadable(
    lazy(() => import('views/staff/assistantDirector/newRegistration/receive-assign-new-applications'))
);
const ApplicationsAssistantDirector = Loadable(
    lazy(() => import('views/staff/assistantDirector/newRegistration/applications-assistant-director'))
);
const ApplicationPriorityChange = Loadable(lazy(() => import('views/staff/assistantDirector/newRegistration/application-priority-change')));
const ReceiveAssignNewRenewal = Loadable(lazy(() => import('views/staff/assistantDirector/renewal/receive-assign-new-renewal')));
const ScrutinizedApplication = Loadable(lazy(() => import('views/staff/assistantDirector/renewal/applications-assistant-director')));
const ChangePriorityAssignedApplications = Loadable(
    lazy(() => import('views/staff/assistantDirector/renewal/application-priority-change'))
);
const ReceiveAssignNewApplicationAC = Loadable(
    lazy(() => import('views/staff/assistantDirector/certificateAmendment/receive-assign-new-amendment-certificate'))
);
const ScrutinizedApplicationAC = Loadable(
    lazy(() => import('views/staff/assistantDirector/certificateAmendment/applications-assistant-director'))
);
const ChangePriorityApplicationsAC = Loadable(
    lazy(() => import('views/staff/assistantDirector/certificateAmendment/application-priority-change'))
);
const ScrutinizedApplicationAO = Loadable(lazy(() => import('views/staff/assistantDirector/otherAmendment/scrutinized-application')));
const RevokeApplicationsSubjectOfficer = Loadable(lazy(() => import('views/staff/assistantDirector/revokeApplicationsSubjectOfficer')));
const AssignedNotAcceptedByFI = Loadable(lazy(() => import('views/staff/assistantDirector/newRegistration/assign-and-not-accepted')));
const AssignedNotAcceptedByFIRenewal = Loadable(lazy(() => import('views/staff/assistantDirector/renewal/assign-and-not-accepted')));
const AssignedNotAcceptedByFIAC = Loadable(
    lazy(() => import('views/staff/assistantDirector/certificateAmendment/assign-and-not-accepted'))
);
const AssignedToFI = Loadable(lazy(() => import('views/staff/assistantDirector/renewal/assign-field-inspector')));
const AmendmentAssignedToFI = Loadable(lazy(() => import('views/staff/assistantDirector/certificateAmendment/assign-field-inspector')));
const ApproveAnnualReturn = Loadable(lazy(() => import('views/staff/assistantDirector/annualReturn/approveSubmission')));

const ProductDetailsReport = Loadable(lazy(() => import('views/staff/report/ProductDetails')));
const SubmissionofAnnualReturns = Loadable(lazy(() => import('views/staff/report/SubmissionOfAnnualReturns')));
const AmendmentCertificateDetails = Loadable(lazy(() => import('views/staff/report/AmendmentCertificateDetails')));
const RenewalCertificateRegistration = Loadable(lazy(() => import('views/staff/report/RenewalCertificateRegistration')));
const NewIndustryRegistrationDetails = Loadable(lazy(() => import('views/staff/report/NewIndustryRegistrationDetails')));
// ==============================|| MAIN ROUTING ||============================== //

const ADMainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/ad/new-registration',
            element: <NewRegistrationAssistantDirector />
        },
        {
            path: '/ad/study-inspection',
            element: <StudyInspection />
        },
        {
            path: '/ad/renewal-application',
            element: <RenewalAssistantDirector />
        },
        {
            path: '/ad/certificate-ammendment',
            element: <CertificateAmendmentAssistantDirector />
        },
        {
            path: '/ad/other-ammendment',
            element: <OtherAmendmentAssistantDirector />
        },
        {
            path: '/ad/annual-return',
            element: <AnnualReturnAssistantDirector />
        },
        {
            path: '/ad/renewel-study-inspection',
            element: <RenewelStudyInspection />
        },
        {
            path: '/ad/amendment-study-inspection',
            element: <AmendmentStudyInspection />
        },
        {
            path: '/ad/revoke-applications',
            element: <RevokeApplications />
        },
        {
            path: '/ad/receive-assign-new-applications',
            element: <ReceiveAssignNewApplications />
        },
        {
            path: '/ad/applications-assistant-director',
            element: <ApplicationsAssistantDirector />
        },

        {
            path: '/ad/application-priority-change',
            element: <ApplicationPriorityChange />
        },
        {
            path: '/ad/receive-assign-new-renewal',
            element: <ReceiveAssignNewRenewal />
        },
        {
            path: '/ad/scrutinized-application',
            element: <ScrutinizedApplication />
        },
        {
            path: '/ad/change-priority-for-assigned-applications',
            element: <ChangePriorityAssignedApplications />
        },
        {
            path: '/ad/assign-fi-renewal',
            element: <AssignedToFI />
        },
        {
            path: '/ad/amendment-certificate-receive-assign-new-applications',
            element: <ReceiveAssignNewApplicationAC />
        },
        {
            path: '/ad/amendment-certificate-scrutinized-application',
            element: <ScrutinizedApplicationAC />
        },
        {
            path: '/ad/amendment-certificate-change-priority-applications',
            element: <ChangePriorityApplicationsAC />
        },
        {
            path: '/ad/other-amendment-scrutinized-application',
            element: <ScrutinizedApplicationAO />
        },
        {
            path: '/ad/revoke-applications-subject-officer',
            element: <RevokeApplicationsSubjectOfficer />
        },
        {
            path: '/ad/assign-application',
            element: <AssignApplication />
        },
        {
            path: '/ad/not-accepted-by-filed-inspector',
            element: <AssignedNotAcceptedByFI />
        },
        {
            path: '/ad/not-accepted-by-filed-inspector-renewal',
            element: <AssignedNotAcceptedByFIRenewal />
        },
        {
            path: '/ad/amendment-certificate-not-accepted-by-filed-inspector',
            element: <AssignedNotAcceptedByFIAC />
        },
        {
            path: '/ad/amendment-certificate-assign-to-filed-inspector',
            element: <AmendmentAssignedToFI />
        },
        {
            path: '/ad/approve-annual-return-submission',
            element: <ApproveAnnualReturn />
        },
        {
            path: '/ad/reports/product-details',
            element: <ProductDetailsReport />
        },
        {
            path: '/ad/reports/submission-annual-reports',
            element: <SubmissionofAnnualReturns />
        },
        {
            path: '/ad/reports/amendment-certificate-details',
            element: <AmendmentCertificateDetails />
        },
        {
            path: '/ad/reports/renewal-certificate-of-registration',
            element: <RenewalCertificateRegistration />
        },
        {
            path: '/ad/reports/new-industry-registration-details',
            element: <NewIndustryRegistrationDetails />
        }
    ]
};

export default ADMainRoutes;
