import { callAPI } from './axios';

// applicant

async function getNotification() {
    const options = {
        method: 'GET',
        url: '/get-all-notification',
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
        }
    };
    const res = await callAPI(options, false);
    return res;
}

async function updateStatus(id) {
    const options = {
        method: 'PUT',
        url: '/mark-as-read',
        params: { id },
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`
        }
    };
    const res = await callAPI(options, false);
    return res;
}

const NotificationServices = {
    getNotification,
    updateStatus
};

export default NotificationServices;
