/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Chip, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';

// assets
import NotificationServices from 'services/notificationService';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ notifications, fetchData }) => {
    const theme = useTheme();
    // const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [readNotifications, setReadNotifications] = useState([]);

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: 28
    };

    // const submitStatus = (id) => {
    //     // Implement your logic to submit the status here
    //     await NotificationServices.updateStatus(id);
    //     console.log(`Status submitted for notification with ID: ${id}`);
    // };

    const submitStatus = async (id) => {
        try {
            // Implement your logic to submit the status here
            await NotificationServices.updateStatus(id);
            setReadNotifications((prevReadNotifications) => [...prevReadNotifications, id]);
            fetchData();
        } catch (error) {
            console.error('Error submitting status:', error);
            // Handle the error appropriately
        }
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 350,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notifications.map((notification, index) => (
                <React.Fragment key={index}>
                    <ListItemWrapper>
                        <ListItem alignItems="center">
                            {/* <ListItemAvatar>
                                <Avatar alt="John Doe" src={User1} />
                            </ListItemAvatar> */}
                            <ListItemText sx={{ mr: 15 }} primary={notification.title} />
                            <ListItemSecondaryAction>
                                <Grid container justifyContent="flex-end">
                                    <Grid item xs={12}>
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {notification.time_ago}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Grid container direction="column">
                            <Grid item xs={12} sx={{ pb: 2, pt: 1 }}>
                                <Typography variant="subtitle2">{notification.message}</Typography>
                            </Grid>
                            {readNotifications.includes(notification.id) ? null : (
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item>
                                            {notification.isRead === 0 && ( // Check if notification is unread
                                                <Chip label="Unread" sx={chipErrorSX} onClick={() => submitStatus(notification.id)} />
                                            )}
                                        </Grid>
                                        {/* <Grid item>
                                        <Chip label="New" sx={chipWarningSX} />
                                    </Grid> */}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </ListItemWrapper>

                    <Divider />
                </React.Fragment>
            ))}
        </List>
    );
};

NotificationList.propTypes = {
    notifications: PropTypes.array,
    fetchData: PropTypes.func
};

export default NotificationList;
