import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const Succes = Loadable(lazy(() => import('views/applicant/industryRegistration/Success')));
const HelpDesk = Loadable(lazy(() => import('views/helpDesk')));
const ChangePassword = Loadable(lazy(() => import('views/changePassword')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/help',
            element: <HelpDesk />
        },
        {
            path: '/change-password',
            element: <ChangePassword />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/success',
            element: <Succes />
        }
    ]
};

export default MainRoutes;
