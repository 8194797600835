import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import { API_END } from '../config';
import { useNavigate } from 'react-router-dom';

// import jwt from 'jsonwebtoken';
// import { JWT_API } from 'config';

// constant
// const JWT_SECRET = JWT_API.secret;
// const JWT_EXPIRES_TIME = JWT_API.timeout;

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    const response = await axios.get(`${API_END}user`);
                    // console.log(response.data);
                    const { user } = response.data;
                    // const { user } = {
                    //     id: '5e86809283e28b96d2d38537',
                    //     email: 'xxx@gmail.com',
                    //     name: 'John Doe',
                    //     type: 'user'
                    // };
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        // const serviceToken = jwt.sign({ userId: '5e86809283e28b96d2d38537' }, JWT_SECRET, { expiresIn: JWT_EXPIRES_TIME });

        const response = await axios.post(`${API_END}login`, { email, password });
        const { serviceToken, user } = response.data;
        setSession(serviceToken);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });

        // console.log('Hello', serviceToken1);
        // const { serviceToken, user } = {
        //     serviceToken: serviceToken1,
        //     user: {
        //         user: {
        //             id: '5e86809283e28b96d2d38537',
        //             email,
        //             name: 'John Doe',
        //             type: 'user'
        //         }
        //     }
        // };
        // let user;
        // if (email === 'info@xxxxxxxxx.com') {
        //     user = {
        //         user: {
        //             id: '5e86809283e28b96d2d38537',
        //             email,
        //             name: 'John Doe',
        //             type: 'user'
        //         }
        //     };
        // } else {
        //     user = {
        //         user: {
        //             id: '5e86809283e28b96d2d38537',
        //             email,
        //             name: 'John Doe',
        //             type: 'staff'
        //         }
        //     };
        // }
        // console.log(user);
        // console.log(serviceToken, user);
        // setSession(serviceToken);
        // dispatch({
        //     type: LOGIN,
        //     payload: {
        //         isLoggedIn: true,
        //         user
        //     }
        // });
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
        navigate('/');
    };

    const resetPassword = (email) => console.log(email);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
