// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconLayoutDashboard } from '@tabler/icons';

// constant
const icons = {
    IconLayoutDashboard
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Dashboard = {
    id: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconLayoutDashboard,
            breadcrumbs: false
        }
    ]
};

export default Dashboard;
