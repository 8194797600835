import { useEffect, useState } from 'react';
import DashboardRDServices from 'services/staffServices/regionalDirectorService/rdDashboardService';

const NewRegistrationCount = () => {
    const [chipNumber, setChipNumber] = useState(0);

    const fetchData = async () => {
        try {
            const res = await DashboardRDServices.getNewIndustryDashboard();
            setChipNumber(res?.row_count?.total_cases);
        } catch (e) {
            // console.log(e);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return chipNumber;
};

export default NewRegistrationCount;
