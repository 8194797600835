// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUserPlus, IconUsers, IconFileUpload } from '@tabler/icons';

// constant
const icons = {
    IconUserPlus,
    IconUsers,
    IconFileUpload
};

// ==============================|| Admin MENU ITEMS ||============================== //

const Admin = {
    id: 'admin',
    type: 'group',
    children: [
        {
            id: 'staff_members',
            title: <FormattedMessage id="staff_members" />,
            type: 'item',
            url: '/admin/staff-members',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'create_staff_member',
            title: <FormattedMessage id="create_staff_member" />,
            type: 'item',
            url: '/admin/create-staff-member',
            icon: icons.IconUserPlus,
            breadcrumbs: false
        },
        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'collapse',
            icon: icons.IconFileUpload,
            children: [
                {
                    id: 'new-industry-registration',
                    title: <FormattedMessage id="new-industry-registration" />,
                    type: 'item',
                    url: '/reports/new-industry-registration-details'
                },
                {
                    id: 'renewal-of-certificate',
                    title: <FormattedMessage id="renewal-of-certificate" />,
                    type: 'item',
                    url: '/reports/renewal-certificate-of-registration'
                },
                {
                    id: 'amendment-of-certificate-details',
                    title: <FormattedMessage id="amendment-of-certificate-details" />,
                    type: 'item',
                    url: '/reports/amendment-certificate-details'
                },
                {
                    id: 'submission-of-annual-returns',
                    title: <FormattedMessage id="submission-of-annual-returns" />,
                    type: 'item',
                    url: '/reports/submission-annual-reports'
                },
                {
                    id: 'product-details',
                    title: <FormattedMessage id="product-details" />,
                    type: 'item',
                    url: '/reports/product-details'
                }
            ]
        }
    ]
};

export default Admin;
