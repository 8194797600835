import { useState, useEffect } from 'react';

// material-ui
import { FormControl, MenuItem, Select } from '@mui/material';

// project imports
import dashboardServices from 'services/applicantServices/applicantDashboard';
import useAuth from 'hooks/useAuth';

const OrganizationsDropdown = () => {
    const [organizations, setOrganizations] = useState([]);
    const { user } = useAuth();
    const [OrgID, setOrgID] = useState(user?.currentOrgID);

    const fetchData = async () => {
        try {
            const res = await dashboardServices.getOrganizations();
            // console.log(res.data);
            setOrganizations(res.data);
        } catch (e) {
            // console.log(e);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const updateCurrentOrgID = async (selectedOrgID) => {
        try {
            await dashboardServices.updateCurrentOrgID(selectedOrgID);
        } catch (e) {
            // console.log(e);
        }
    };

    const handleChange = (event) => {
        const selectedOrgID = event.target.value;
        setOrgID(selectedOrgID);
        updateCurrentOrgID(selectedOrgID);
    };

    return (
        <FormControl sx={{ minWidth: { lg: '250px', xs: '100%' } }}>
            <Select size="small" labelId="org-select" id="organizations" name="organizations" value={OrgID} onChange={handleChange}>
                {organizations.map((item, index) => (
                    <MenuItem value={item.orgId} key={index}>
                        {item.orgName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default OrganizationsDropdown;
