import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const OrganizationDetails = Loadable(lazy(() => import('views/applicant/applicantDashboard/comp/OrganizationDetails')));
const IndustryRegistration = Loadable(lazy(() => import('views/applicant/industryRegistration')));
const RenewalApplication = Loadable(lazy(() => import('views/applicant/renewalApplication')));
const RenewalForm = Loadable(lazy(() => import('views/applicant/renewalApplication/renewalForm')));
const CertificateAmendment = Loadable(lazy(() => import('views/applicant/amendmentCertificate')));
const CertificateAmendmentForm = Loadable(lazy(() => import('views/applicant/amendmentCertificate/amendmentCertificateForm')));
const OtherAmendment = Loadable(lazy(() => import('views/applicant/amendmentOfOtherDetails')));
const AnnualReturn = Loadable(lazy(() => import('views/applicant/annualReturn')));
const AnnualReturnForm = Loadable(lazy(() => import('views/applicant/annualReturn/annualReturnForm')));

// ==============================|| MAIN ROUTING ||============================== //

const ApplicantMainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/organization-details',
            element: <OrganizationDetails />
        },
        {
            path: '/industry-registration',
            element: <IndustryRegistration />
        },
        {
            path: '/renewal-application',
            children: [
                {
                    path: '',
                    element: <RenewalApplication />
                },
                {
                    path: ':id',
                    element: <RenewalForm />
                }
            ]
        },
        {
            path: '/certificate-ammendment',
            children: [
                {
                    path: '',
                    element: <CertificateAmendment />
                },
                {
                    path: ':id',
                    element: <CertificateAmendmentForm />
                }
            ]
        },
        {
            path: '/other-ammendment',
            element: <OtherAmendment />
        },
        {
            path: '/annual-return',
            children: [
                {
                    path: '',
                    element: <AnnualReturn />
                },
                {
                    path: ':id',
                    element: <AnnualReturnForm />
                }
            ]
        }
    ]
};

export default ApplicantMainRoutes;
